import { post } from "./service";

export const get_users = (payload, onDone) => {
    post("/api/users/grid", payload, (res) => {
        onDone(res);
    });
};

export const is_username_valid = (payload, onDone) => {
    post("/api/users/is_username_valid", payload, (res) => {
        onDone(res);
    });
};

export const upsert_user = (payload, onDone) => {
    post("/api/users/upsert", payload, (res) => {
        onDone(res);
    });
};

export const send_password_by_id = (payload, onDone) => {
    post("/api/users/send_password", payload, (res) => {
        onDone(res);
    });
};
export const get_user_roles = (payload, onDone) => {
    post("/api/users/user_roles", payload, (res) => {
        onDone(res);
    });
};

export const insert_user_role = (payload, onDone) => {
    post("/api/users/insert_user_role", payload, (res) => {
        onDone(res);
    });
};

export const delete_user_role = (payload, onDone) => {
    post("/api/users/delete_user_role", payload, (res) => {
        onDone(res);
    });
};