import { arrayToMap } from "../components/Util";
import { post } from "./service";

export const get_common_lookups = (payload, onDone) => {
    post("/api/lookups/common", payload, (res) => {
        const lookup = arrayToMap(res.data || [], 'family')
        onDone(lookup);
    });
};

